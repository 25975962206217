import React, { useState, useEffect, Fragment } from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { format } from "date-fns";
import {
    CreateMeeting,
    getMeetingStatusList,
    getMeetingTypeList,
    getMeetingRequestList,
    getMeetingSetupList,
} from "../../../../../services/ccms/meetingsManagement";
import { LocationListView } from "../../../../../services/ccms/locationManagement/location/location_endpoints";
import StepDisclaimer from "./Steps/StepDisclaimer";
import StepOne from "./Steps/StepOne";
import StepTwo from "./Steps/StepTwo";
import StepThree from "./Steps/StepThree";
import StepFour from "./Steps/StepFour";
import StepFive from "./Steps/StepFive";
import StepSix from "./Steps/StepSix";
import StepSeven from "./Steps/StepSeven";

const NewMeeting = () => {
    const [meetingDetails, setMeetingDetails] = useState({
        meetingID: 0,
        title: "",
        description: "",
        statusID: 0,
        typeID: 0,
        startDate: "",
        endDate: "",
        setupDate: null,
        cleanupDate: null,
        organizerID: "",
        noOfAttendees: 0,
        createdDateTime: "",
        modifiedDateTime: "",
        meetingVenue: {
            venueID: null,
            isExternal: false,
            isVirtual: false,
            isInternal: false,
            address: "",
            details: "",
        },
        meetingSetups: [],
        meetingRequests: [],
        meetingAttendees: [],
    });

    const [currentStep, setCurrentStep] = useState(1);
    const [meetingTypes, setMeetingTypes] = useState([]);
    const [meetingStatuses, setMeetingStatuses] = useState([]);
    const [meetingRequests, setMeetingRequests] = useState([]);
    const [meetingSetups, setMeetingSetups] = useState([]);
    const [locations, setLocations] = useState([]);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [selectedLocation, setSelectedLocation] = useState(""); // State for selected location


    const navigate = useNavigate();

    useEffect(() => {
        fetchInitialData();
    }, []);

    const fetchInitialData = async () => {
        try {
            const [
                typesResponse,
                statusesResponse,
                requestsResponse,
                setupsResponse,
                locationsResponse,
            ] = await Promise.all([
                getMeetingTypeList(),
                getMeetingStatusList(),
                getMeetingRequestList(),
                getMeetingSetupList(),
                LocationListView(),
            ]);

            setMeetingTypes(typesResponse.results);
            setMeetingStatuses(statusesResponse.results);
            setMeetingRequests(requestsResponse.results);
            setMeetingSetups(setupsResponse.results);
            setLocations(locationsResponse.results);
        } catch (error) {
            console.error("Error fetching initial data:", error);
        }
    };

    const handleMeetingDetailChange = (key, value) => {
        setMeetingDetails((prev) => {
            if (key.startsWith("meetingVenue.")) {
                const [, subKey] = key.split(".");
                return {
                    ...prev,
                    meetingVenue: {
                        ...prev.meetingVenue,
                        [subKey]: value,
                    },
                };
            }
            return {
                ...prev,
                [key]: value,
            };
        });
    };

    const handleNextStep = () => {
        setCurrentStep((prev) => prev + 1);
    };

    const handlePreviousStep = () => {
        setCurrentStep((prev) => prev - 1);
    };

    const handleSubmit = async () => {
        setIsSubmitting(true);

        const now = new Date();
        const timestamp = format(now, "yyyyMMddHHmmss");

        const data = {
            ...meetingDetails,
            createdDateTime: timestamp,
            modifiedDateTime: timestamp,
        };

        try {
            const response = await CreateMeeting(data);
            if (response.status === "Success") {
                Swal.fire({
                    title: "Success",
                    text: "Meeting created successfully!",
                    icon: "success",
                });
                
                navigate('/app/meetings/view-meeting', { state: response.results });
            } else {
                Swal.fire({
                    title: "Error",
                    text: response.message,
                    icon: "error",
                });
            }
        } catch (error) {
            Swal.fire({
                title: "Error",
                text: "An error occurred while creating the meeting.",
                icon: "error",
            });
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <Fragment>
            <div className="steps-container">
                {currentStep === 1 && (
                    <StepDisclaimer
                        handleNextStep={handleNextStep}
                        meetingDetails={meetingDetails}
                    />
                )}
                {currentStep === 2 && (
                    <StepOne
                        meetingTypes={meetingTypes}
                        meetingDetails={meetingDetails}
                        handleMeetingDetailChange={handleMeetingDetailChange}
                        handlePreviousStep={handlePreviousStep}
                        handleNextStep={handleNextStep}
                    />
                )}
                {currentStep === 3 && (
                    <StepTwo
                        meetingDetails={meetingDetails}
                        handleMeetingDetailChange={handleMeetingDetailChange}
                        handlePreviousStep={handlePreviousStep}
                        handleNextStep={handleNextStep}
                    />
                )}
                {currentStep === 4 && (
                    <StepThree
                        meetingDetails={meetingDetails}
                        locations={locations}
                        selectedLocation={selectedLocation} // Pass the selected location
                        setSelectedLocation={setSelectedLocation} // Pass the function to update it
                        handleMeetingDetailChange={handleMeetingDetailChange}
                        handlePreviousStep={handlePreviousStep}
                        handleNextStep={handleNextStep}
                    />
                )}
                {currentStep === 5 && (
                    <StepFour
                        meetingDetails={meetingDetails}
                        locations={locations}
                        selectedLocation={selectedLocation} // Pass the selected location
                        setSelectedLocation={setSelectedLocation} // Pass the function to update it
                        handleMeetingDetailChange={handleMeetingDetailChange}
                        handlePreviousStep={handlePreviousStep}
                        handleNextStep={handleNextStep}
                    />
                )}
                {currentStep === 6 && (
                    <StepFive
                        meetingSetups={meetingSetups}
                        meetingDetails={meetingDetails}
                        handleMeetingDetailChange={handleMeetingDetailChange}
                        handlePreviousStep={handlePreviousStep}
                        handleNextStep={handleNextStep}
                    />
                )}
                {currentStep === 7 && (
                    <StepSix
                        meetingRequests={meetingRequests}
                        meetingDetails={meetingDetails}
                        handleMeetingDetailChange={handleMeetingDetailChange}
                        handlePreviousStep={handlePreviousStep}
                        handleNextStep={handleNextStep}
                    />
                )}
                {currentStep === 8 && (
                    <StepSeven
                        meetingDetails={meetingDetails}
                        handleMeetingDetailChange={handleMeetingDetailChange}
                        handlePreviousStep={handlePreviousStep}
                        handleSubmit={handleSubmit}
                        isSubmitting={isSubmitting}
                    />
                )}
            </div>
        </Fragment>
    );
};

export default NewMeeting;

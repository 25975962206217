import { getApiMeetings } from '../../APIServiceSetup';

const api_meetings = await getApiMeetings();
const CACHE_KEY = 'meetingAttendeeCache';
const CACHE_TIMEOUT = 30 * 24 * 60 * 60 * 1000; // 30 days in milliseconds

// Helper functions for cache management
const isCacheValid = (cacheKey) => {
    const cachedData = JSON.parse(localStorage.getItem(cacheKey));
    if (!cachedData) return false;
    const now = new Date().getTime();
    return now - cachedData.timestamp < CACHE_TIMEOUT;
};

const setCache = (cacheKey, data) => {
    const cachedData = {
        data,
        timestamp: new Date().getTime(),
    };
    localStorage.setItem(cacheKey, JSON.stringify(cachedData));
};

const getCache = (cacheKey) => {
    const cachedData = JSON.parse(localStorage.getItem(cacheKey));
    return cachedData ? cachedData.data : null;
};

// Get a list of meeting attendees
export const getMeetingAttendeeList = async (forceUpdate = false) => {
    if (!forceUpdate && isCacheValid(CACHE_KEY)) {
        return getCache(CACHE_KEY);
    }

    try {
        const response = await api_meetings.get('/api/MeetingAttendee/List');
        setCache(CACHE_KEY, response.data);
        return response.data;
    } catch (error) {
        return Promise.reject(error);
    }
};

// Get a specific meeting attendee by ID
export const getMeetingAttendeeById = async (id, forceUpdate = false) => {
    const cacheKey = `${CACHE_KEY}_${id}`;

    if (!forceUpdate && isCacheValid(cacheKey)) {
        return getCache(cacheKey);
    }

    try {
        const response = await api_meetings.get(`/api/MeetingAttendee/Get/${id}`);
        setCache(cacheKey, response.data);
        return response.data;
    } catch (error) {
        return Promise.reject(error);
    }
};

// Save a new meeting attendee
export const saveMeetingAttendee = async (attendeeData) => {
    try {
        const response = await api_meetings.post('/api/MeetingAttendee/Save', attendeeData);
        localStorage.removeItem(CACHE_KEY); // Invalidate cache
        return response.data;
    } catch (error) {
        return Promise.reject(error);
    }
};

// Update an existing meeting attendee
export const updateMeetingAttendee = async (attendeeData) => {
    try {
        const response = await api_meetings.post('/api/MeetingAttendee/Update', attendeeData);
        localStorage.removeItem(CACHE_KEY); // Invalidate cache
        return response.data;
    } catch (error) {
        return Promise.reject(error);
    }
};

// Delete a meeting attendee
export const deleteMeetingAttendee = async (id) => {
    try {
        const response = await api_meetings.delete(`/api/MeetingAttendee/Delete/${id}`);
        localStorage.removeItem(CACHE_KEY); // Invalidate cache
        return response.data;
    } catch (error) {
        return Promise.reject(error);
    }
};

// Meeting attendance response
export const MeetingAttendanceResponse = async (response) => {
    try {
        const responseData = await api_meetings.post('/api/MeetingAttendee/MeetingResponse', response);
        return responseData.data;
    } catch (error) {
        return Promise.reject(error);
    }
};
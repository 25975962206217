import React, { Fragment, useState, useEffect, useContext, useCallback } from 'react';
import { useNavigate, useLocation } from "react-router-dom"; // <-- Added useLocation
import queryString from 'query-string';
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";
import { Button, Form, Row, Col, Card } from 'react-bootstrap';
import DataTable, { SortOrder } from 'react-data-table-component';
import Shimmer from '../../../components/Custom/Loading/Shimmer';
import { UserRightsContext } from '../../../../context/UserRightsContext';
import { useMediaQuery } from 'react-responsive';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSyncAlt, faPlus } from '@fortawesome/free-solid-svg-icons';

// API Services
import { getFilteredMeetingList, getMeetingStatusList, getMeetingTypeList } from '../../../../services/ccms/meetingsManagement';
import { TextColor } from '../../../../utilities/colorUtils';

const Meetings = () => {
    const today = new Date();
    const pastDate = new Date(today);
    pastDate.setDate(today.getDate() - 30);
    const futureDate = new Date(today);
    futureDate.setDate(today.getDate() + 30);

    const [statusList, setStatusList] = useState([]);
    const [typeList, setTypeList] = useState([]);
    const [meetingList, setMeetingList] = useState([]);
    const [isLoaded, setIsLoaded] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const userRights = useContext(UserRightsContext);
    const [userDetails, setUserDetails] = useState(null);
    const [forceRefresh, setForceRefresh] = useState(false);
    const navigate = useNavigate();
    const location = useLocation(); // <-- Get location from react-router

    const [filters, setFilters] = useState({
        startDate: pastDate.toISOString().split('T')[0],
        endDate: futureDate.toISOString().split('T')[0],
        statusID: null,
        typeID: null,
        organizerID: null,
    });

    // Check the query string on component mount
    useEffect(() => {
        const queryParams = queryString.parse(location.search);
        if (queryParams.refresh !== undefined) {
            setForceRefresh(true);
        }
    }, [location.search]);

    useEffect(() => {
        const storedUserDetails = JSON.parse(localStorage.getItem('userDetails'));
        setUserDetails(storedUserDetails);
    }, []);

    useEffect(() => {
        if (userDetails && (forceRefresh || filters)) {
            fetchInitialData();
        }
    }, [userDetails, filters, forceRefresh, page, pageSize]);

    const fetchInitialData = useCallback(async () => {
        setIsLoaded(false);
        await fetchStatusList();
        await fetchTypeList();
        await fetchMeetings();
        setForceRefresh(false); // Reset forceRefresh after data fetch
        setIsLoaded(true);
    }, [userDetails, filters, forceRefresh, page, pageSize]);

    const fetchStatusList = async () => {
        const response = await getMeetingStatusList();
        setStatusList(response.results);
    };

    const fetchTypeList = async () => {
        const response = await getMeetingTypeList();
        setTypeList(response.results);
    };

    const fetchMeetings = useCallback(async () => {
        const isAdmin = hasRequiredRights(['TaskManagementAdmin']);
        const filterData = {
          ...filters,
          organizerID: isAdmin ? null : userDetails.userId,
        };
        const response = await getFilteredMeetingList(filterData, page, pageSize, forceRefresh);
        
        // Sort the results by meetingID descending
        const sortedResults = response.results.sort((a, b) => b.meetingID - a.meetingID);
        
        setMeetingList(sortedResults);
        setTotalRows(response.resultCount);
        setForceRefresh(false);
        setIsLoaded(true);
      }, [filters, userDetails, forceRefresh, page, pageSize]);

    const hasRequiredRights = (requiredRights) => {
        return requiredRights.every((right) => userRights.includes(right));
    };

    const handleDateRangeChange = (event, picker) => {
        setFilters((prevFilters) => ({
            ...prevFilters,
            startDate: picker.startDate.format('YYYY-MM-DD'),
            endDate: picker.endDate.format('YYYY-MM-DD'),
        }));
        setForceRefresh(true); // Trigger a force update
    };
    
    const handleStatusChange = (event) => {
        setFilters((prevFilters) => ({
            ...prevFilters,
            statusID: event.target.value === "NULL" ? null : parseInt(event.target.value, 10),
        }));
        setForceRefresh(true); // Trigger a force update
    };
    
    const handleTypeChange = (event) => {
        setFilters((prevFilters) => ({
            ...prevFilters,
            typeID: event.target.value === "NULL" ? null : parseInt(event.target.value, 10),
        }));
        setForceRefresh(true); // Trigger a force update
    };

    const handleFilterApply = () => {
        fetchInitialData(); // Trigger data fetch with the current filters
    };

    const handleNewMeetingClick = () => {
        navigate('/app/meetings/new-meeting');
    };

    const handleOpenMeeting = (meeting) => {
        navigate('/app/meetings/view-meeting', { state: meeting });
    };

    const handlePageChange = (newPage) => {
        setPage(newPage);
    };

    const handlePageSizeChange = (newPageSize) => {
        setPageSize(newPageSize);
        setPage(1); // Reset to first page
    };

    const isMobile = useMediaQuery({ maxWidth: 767 });

    const columns = [
        {
            name: 'ID',
            selector: row => row.meetingID,
            sortable: true,
            width: '5em',
        },
        {
            name: 'Title',
            selector: row => row.title,
            sortable: true,
            width: isMobile ? 'auto' : '20%',
        },
        {
            name: 'Date',
            selector: row => row.startDate.slice(0, 10),
            sortable: true,
        },
        {
            name: 'Start Time',
            selector: row => row.startDate.split('T')[1],
            sortable: true,
        },
        {
            name: 'End Time',
            selector: row => row.endDate.split('T')[1],
            sortable: true,
        },
        {
            name: 'Status',
            selector: row => (
                <span className='badge' style={{ backgroundColor: row.statusColor }}>
                    <TextColor bgColor={row.statusColor} text={row.statusName} />
                </span>
            ),
            sortable: true,
        },
        {
            name: 'Type',
            selector: row => (
                <span className='badge' style={{ backgroundColor: row.typeColor }}>
                    <TextColor bgColor={row.typeColor} text={row.typeName} />
                </span>
            ),
            sortable: true,
        },
        {
            name: 'Organizer',
            selector: row => `${row.organizerName} ${row.organizerSurname}`,
            sortable: true,
        },
        {
            name: '',
            cell: row => (
                <Button variant="outline-primary" size="xs" onClick={() => handleOpenMeeting(row)}>
                    <i className='fas fa-eye'></i> View
                </Button>
            ),
            ignoreRowClick: true,
            width: '10em',
        },
    ];

    return (
        <Fragment>
            <div className="d-flex justify-content-between align-items-center mb-4">
                <Button variant="success" size="sm" onClick={handleNewMeetingClick}>
                    <FontAwesomeIcon icon={faPlus} size="sm" /> Create Meeting
                </Button>
                <Button size="xs" onClick={() => setForceRefresh(true)}>
                    <FontAwesomeIcon icon={faSyncAlt} />
                </Button>
            </div>
            <Card className="shadow">
                <Card.Body>
                    <Row>
                        <Col md={3}>
                            <Form.Group>
                                <Form.Label>Date</Form.Label>
                                <DateRangePicker
                                    initialSettings={{ startDate: filters.startDate, endDate: filters.endDate, locale: { format: 'YYYY/MM/DD' } }}
                                    onApply={handleDateRangeChange}
                                >
                                    <Form.Control type="text" className="input-daterange-timepicker" />
                                </DateRangePicker>
                            </Form.Group>
                        </Col>
                        <Col md={3}>
                            <Form.Group>
                                <Form.Label>Status</Form.Label>
                                <Form.Control as="select" value={filters.statusID || "NULL"} onChange={handleStatusChange}>
                                    <option value="NULL">Any Status</option>
                                    {statusList.map(status => (
                                        <option key={status.statusID} value={status.statusID}>
                                            {status.name}
                                        </option>
                                    ))}
                                </Form.Control>
                            </Form.Group>
                        </Col>
                        <Col md={3}>
                            <Form.Group>
                                <Form.Label>Type</Form.Label>
                                <Form.Control as="select" value={filters.typeID || "NULL"} onChange={handleTypeChange}>
                                    <option value="NULL">Any Type</option>
                                    {typeList.map(type => (
                                        <option key={type.typeID} value={type.typeID}>
                                            {type.name}
                                        </option>
                                    ))}
                                </Form.Control>
                            </Form.Group>
                        </Col>
                        <Col md={3} className="d-flex align-items-center justify-content-end">
                            <Button variant="warning" size="sm" onClick={handleFilterApply}>
                                <i className='fas fa-filter'></i> Apply
                            </Button>
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
            <Card className="shadow mt-3">
                <Card.Body>
                    <div className="table-responsive">
                        {isLoaded ? (
                            <DataTable
                                columns={columns}
                                data={meetingList}
                                defaultSortField="meetingID"
                                defaultSortAsc={false} // false sets descending order
                                pagination
                                paginationServer
                                paginationTotalRows={totalRows}
                                onChangePage={handlePageChange}
                                onChangeRowsPerPage={handlePageSizeChange}
                                paginationPerPage={pageSize}
                            />
                        ) : (
                            <Shimmer type="table" width={'100%'} />
                        )}
                    </div>
                </Card.Body>
            </Card>
        </Fragment>
    );
};

export default Meetings;

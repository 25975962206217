import { getApiMeetings } from '../../APIServiceSetup';

const api_meetings = await getApiMeetings();
const CACHE_KEY = 'meetingCache';
const CACHE_TIMEOUT = 30 * 24 * 60 * 60 * 1000; // 30 days in milliseconds

// Helper functions for cache management
const isCacheValid = (cacheKey) => {
    const cachedData = JSON.parse(localStorage.getItem(cacheKey));
    if (!cachedData) return false;
    const now = new Date().getTime();
    return now - cachedData.timestamp < CACHE_TIMEOUT;
};

const setCache = (cacheKey, data) => {
    const cachedData = {
        data,
        timestamp: new Date().getTime(),
    };
    localStorage.setItem(cacheKey, JSON.stringify(cachedData));
};

const getCache = (cacheKey) => {
    const cachedData = JSON.parse(localStorage.getItem(cacheKey));
    return cachedData ? cachedData.data : null;
};

// Get a list of meetings
export const getMeetingList = async (forceUpdate = false) => {
    if (!forceUpdate && isCacheValid(CACHE_KEY)) {
        return getCache(CACHE_KEY);
    }

    try {
        const response = await api_meetings.get('/api/Meeting/List');
        setCache(CACHE_KEY, response.data);
        return response.data;
    } catch (error) {
        return Promise.reject(error);
    }
};

// Get a specific meeting by ID
export const getMeetingById = async (id, forceUpdate = false) => {
    const cacheKey = `${CACHE_KEY}_${id}`;

    if (!forceUpdate && isCacheValid(cacheKey)) {
        return getCache(cacheKey);
    }

    try {
        const response = await api_meetings.get(`/api/Meeting/Get/${id}`);
        setCache(cacheKey, response.data);
        return response.data;
    } catch (error) {
        return Promise.reject(error);
    }
};

// Save a new meeting
export const saveMeeting = async (meetingData) => {
    try {
        const response = await api_meetings.post('/api/Meeting/Save', meetingData);
        localStorage.removeItem(CACHE_KEY); // Invalidate cache
        return response.data;
    } catch (error) {
        return Promise.reject(error);
    }
};

// Update an existing meeting
export const updateMeeting = async (meetingData) => {
    try {
        const response = await api_meetings.post('/api/Meeting/Update', meetingData);
        localStorage.removeItem(CACHE_KEY); // Invalidate cache
        return response.data;
    } catch (error) {
        return Promise.reject(error);
    }
};

// Delete a meeting
export const deleteMeeting = async (id) => {
    try {
        const response = await api_meetings.delete(`/api/Meeting/Delete/${id}`);
        localStorage.removeItem(CACHE_KEY); // Invalidate cache
        return response.data;
    } catch (error) {
        return Promise.reject(error);
    }
};

// Get paginated meeting list
export const getPaginatedMeetingList = async (page, pageSize, forceUpdate = false) => {
    const cacheKey = `${CACHE_KEY}_page_${page}_size_${pageSize}`;

    if (!forceUpdate && isCacheValid(cacheKey)) {
        return getCache(cacheKey);
    }

    try {
        const response = await api_meetings.get('/api/Meeting/PaginatedList', { params: { page, pageSize } });
        setCache(cacheKey, response.data);
        return response.data;
    } catch (error) {
        return Promise.reject(error);
    }
};

// Create new meeting '/api/Meeting/CreateMeeting'
export const CreateMeeting = async (meetingData) => {
    try {
        const response = await api_meetings.post('/api/Meeting/CreateMeeting', meetingData);
        return response.data;
    } catch (error) {
        return Promise.reject(error);
    }
};

// Get meeting list view and cache it '/api/Meeting/ListView' with forceUpdate = false
export const getMeetingListView = async (forceUpdate = false) => {
    const cacheKey = 'meetingListView';

    if (!forceUpdate && isCacheValid(cacheKey)) {
        return getCache(cacheKey);
    }

    try {
        const response = await api_meetings.get('/api/Meeting/ListView');
        setCache(cacheKey, response.data);
        return response.data;
    } catch (error) {
        return Promise.reject(error);
    }
};

// Get filtered list of meetings with pagination
export const getFilteredMeetingList = async (filters, page = 1, pageSize = 0, forceUpdate = false) => {
    const cacheKey = `filteredMeetingList_${JSON.stringify(filters)}_page_${page}_size_${pageSize}`;

    if (!forceUpdate && isCacheValid(cacheKey)) {
        return getCache(cacheKey);
    }

    try {
        const response = await api_meetings.post('/api/Meeting/FilteredListView', { ...filters, page, pageSize });
        setCache(cacheKey, response.data);
        return response.data;
    } catch (error) {
        return Promise.reject(error);
    }
};

// Get individual user meeting list
export const getUserMeetingList = async (forceUpdate = false) => {
    const cacheKey = 'userMeetingList';

    if (!forceUpdate && isCacheValid(cacheKey)) {
        return getCache(cacheKey);
    }

    try {
        const response = await api_meetings.get('/api/Meeting/UserMeetingList');
        setCache(cacheKey, response.data);
        return response.data;
    } catch (error) {
        return Promise.reject(error);
    }
};

// Get invited user meeting list
export const getInvitedMeetingList = async (forceUpdate = false) => {
    const cacheKey = 'invitedMeetingList';

    if (!forceUpdate && isCacheValid(cacheKey)) {
        return getCache(cacheKey);
    }

    try {
        const response = await api_meetings.get('/api/Meeting/InvitedMeetingList');
        setCache(cacheKey, response.data);
        return response.data;
    } catch (error) {
        return Promise.reject(error);
    }
};

// Get attending meeting list by userID
export const getAttendingMeetingList = async (userID, forceUpdate = false) => {
    const cacheKey = `attendingMeetingList_${userID}`;

    if (!forceUpdate && isCacheValid(cacheKey)) {
        return getCache(cacheKey);
    }

    try {
        const response = await api_meetings.get(`/api/Meeting/AttendingMeetingList/${userID}`);
        setCache(cacheKey, response.data);
        return response.data;
    } catch (error) {
        return Promise.reject(error);
    }
};
// src/components/Meetings/Overview/CurrentMeetings.jsx
import React from 'react';
import { Card, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import 'react-perfect-scrollbar/dist/css/styles.css';
import Shimmer from '../../../../components/Custom/Loading/Shimmer';
import { friendlyTimeDifference } from '../../../../../utilities/DateTimeManipulator';

const CurrentMeetings = ({ meetings }) => {
  const navigate = useNavigate();
  const now = new Date();

  // Filter for current meetings: now is between startDate and endDate
  const currentMeetings = meetings.filter(meeting => {
    const start = new Date(meeting.startDate);
    const end = new Date(meeting.endDate);
    return now >= start && now <= end;
  });

  // Sort current meetings in ascending order by startDate
  const sortedCurrentMeetings = currentMeetings.sort((a, b) => {
    return new Date(a.startDate) - new Date(b.startDate);
  });

  const handleViewMeeting = (meeting) => {
    navigate('/app/meetings/view-meeting', { state: meeting });
  };

  return (
    <Card
      className="shadow"
      style={{ maxHeight: '40.5em', display: 'flex', flexDirection: 'column' }}
    >
      <Card.Header>
        Current Meetings (Total: {sortedCurrentMeetings.length})
      </Card.Header>
      <Card.Body className="d-flex flex-column" style={{ overflow: 'hidden' }}>
        <PerfectScrollbar className="flex-grow-1 px-3" style={{ maxHeight: '100%' }}>
          {sortedCurrentMeetings.length === 0 ? (
            <Shimmer type="empty" text="No current meetings." />
          ) : (
            sortedCurrentMeetings.map(meeting => (
              <div
                key={meeting.meetingID}
                className="msg-bx d-flex justify-content-between align-items-center border-bottom py-2"
              >
                <div className="msg d-flex align-items-center w-100">
                  <div className="image-box">
                    <img
                      src={meeting.organizerAvatar}
                      style={{ width: '3.5em', height: '3.5em' }}
                      alt=""
                    />
                  </div>
                  <div className="ms-3 w-100">
                    <h6 className="fs-18 font-w600 mb-0">{meeting.title}</h6>
                    <small className="text-muted">
                      {meeting.startDate
                        ? new Date(meeting.startDate).toDateString()
                        : 'No Date'}{' '}
                      |{' '}
                      {meeting.startDate
                        ? new Date(meeting.startDate).toLocaleTimeString()
                        : 'No Time'}{' '}
                      to{' '}
                      {meeting.endDate
                        ? new Date(meeting.endDate).toLocaleTimeString()
                        : 'No Time'}
                    </small>
                    <div className="d-flex justify-content-between">
                      <small className="text-muted">
                        Organizer: {meeting.organizerName} {meeting.organizerSurname}
                      </small>
                      <span className="me-4 fs-12">
                        {friendlyTimeDifference(new Date(), new Date(meeting.startDate))}
                      </span>
                    </div>
                  </div>
                </div>
                <Button
                  variant="outline-primary"
                  size="xxs"
                  onClick={() => handleViewMeeting(meeting)}
                >
                  <FontAwesomeIcon icon={faEye} />
                </Button>
              </div>
            ))
          )}
        </PerfectScrollbar>
      </Card.Body>
    </Card>
  );
};

export default CurrentMeetings;

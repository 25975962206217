// src/components/Meetings/Overview/Statistics.jsx
import React, { useEffect, useState } from 'react';
import { Row, Col, Card, Modal, Button } from 'react-bootstrap';
import CountUp from 'react-countup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faCheck, faTasks } from '@fortawesome/free-solid-svg-icons';
import Shimmer from '../../../../components/Custom/Loading/Shimmer';
import DataTable from 'react-data-table-component';
import { useMediaQuery } from 'react-responsive';
import { useNavigate } from 'react-router-dom';
import { getTextColor, TextColor } from '../../../../../utilities/colorUtils';

const Statistics = ({ meetings }) => {
  // State for overall stats and location breakdowns
  const [stats, setStats] = useState(null);
  const [currentLocationStats, setCurrentLocationStats] = useState({});
  const [upcomingLocationStats, setUpcomingLocationStats] = useState({});
  const [loading, setLoading] = useState(true);

  // Modal state for displaying detailed meeting info
  const [modalShow, setModalShow] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalData, setModalData] = useState([]);

  const isMobile = useMediaQuery({ maxWidth: 767 });
  const navigate = useNavigate();

  // Define the DataTable columns for the modal popup
  const columns = [
    // {
    //   name: 'ID',
    //   selector: row => row.meetingID,
    //   sortable: true,
    //   width: '5em',
    // },
    {
      name: 'Title',
      selector: row => row.title,
      sortable: true,
      width: isMobile ? 'auto' : '20%',
    },
    {
      name: 'Date',
      selector: row => row.startDate ? row.startDate.slice(0, 10) : '',
      sortable: true,
    },
    {
      name: 'Start Time',
      selector: row => row.startDate ? row.startDate.split('T')[1] : '',
      sortable: true,
    },
    {
      name: 'End Time',
      selector: row => row.endDate ? row.endDate.split('T')[1] : '',
      sortable: true,
    },
    {
      name: 'Status',
      selector: row => (
        <span className='badge' style={{ backgroundColor: row.statusColor }}>
          <TextColor bgColor={row.statusColor} text={row.statusName} />
        </span>
      ),
      sortable: true,
    },
    {
      name: 'Type',
      selector: row => (
        <span className='badge' style={{ backgroundColor: row.typeColor }}>
          <TextColor bgColor={row.typeColor} text={row.typeName} />
        </span>
      ),
      sortable: true,
    },
    {
      name: 'Organizer',
      selector: row => `${row.organizerName} ${row.organizerSurname}`,
      sortable: true,
    },
    {
      name: '',
      cell: row => (
        <Button variant="outline-primary" size="xxs" onClick={() => handleOpenMeeting(row)}>
          <i className='fas fa-eye'></i> View
        </Button>
      ),
      ignoreRowClick: true,
      width: '10em',
    },
  ];

  // Open the meeting detail page from the modal DataTable
  const handleOpenMeeting = (meeting) => {
    navigate('/app/meetings/view-meeting', { state: meeting });
  };

  // Compute stats and breakdowns when meetings change
  useEffect(() => {
    const now = new Date();

    // Overall counts (adjust statusIDs as needed)
    const pendingCount = meetings.filter(m => m.statusID === 0).length;
    const approvedCount = meetings.filter(m => m.statusID === 1).length;
    const totalCount = meetings.length;

    // Create widget data with an extra filter function for later use
    const computedStats = [
      {
        statusID: 0,
        name: 'Pending Meetings',
        count: pendingCount,
        color: '#FFA500', // Orange
        icon: faPlus,
        filter: (m) => m.statusID === 0,
      },
      {
        statusID: 1,
        name: 'Approved Meetings',
        count: approvedCount,
        color: '#28a745', // Green
        icon: faCheck,
        filter: (m) => m.statusID === 1,
      },
      {
        statusID: 'total',
        name: 'Total Meetings',
        count: totalCount,
        color: '#17a2b8', // Blue
        icon: faTasks,
        filter: () => true,
      },
    ];

    // Filter meetings for current and upcoming categories
    const currentMeetings = meetings.filter(m => {
      const start = new Date(m.startDate);
      const end = new Date(m.endDate);
      return now >= start && now <= end;
    });
    const upcomingMeetings = meetings.filter(m => new Date(m.startDate) > now);

    // Helper function: group meetings by location and store both count and the filtered data
    const groupByLocation = (meetingArray) => {
      return meetingArray.reduce((acc, meeting) => {
        const location = (meeting.meetingVenueData && meeting.meetingVenueData.venueName) || 'Unknown';
        if (!acc[location]) {
          acc[location] = { count: 0, meetings: [] };
        }
        acc[location].count += 1;
        acc[location].meetings.push(meeting);
        return acc;
      }, {});
    };

    const currentLocations = groupByLocation(currentMeetings);
    const upcomingLocations = groupByLocation(upcomingMeetings);

    setStats(computedStats);
    setCurrentLocationStats(currentLocations);
    setUpcomingLocationStats(upcomingLocations);
    setLoading(false);
  }, [meetings]);

  // When a widget card is clicked, filter meetings using its filter function and open the modal.
  const handleWidgetClick = (stat) => {
    const data = meetings.filter(stat.filter);
    setModalTitle(stat.name);
    setModalData(data);
    setModalShow(true);
  };

  // When a location item is clicked, open the modal with meetings for that location.
  const handleLocationClick = (category, location, dataArray) => {
    setModalTitle(`${category} Meetings at ${location}`);
    setModalData(dataArray);
    setModalShow(true);
  };

  if (loading) {
    return <Shimmer type="sections" />;
  }

  return (
    <>
      {/* Overall Meeting Stats as Widget Cards */}
      <Row>
        {stats.map(stat => (
          <Col key={stat.statusID} xs={12} sm={6} md={4}>
            <Card
              className="widget-stat shadow mb-4 clickable"
              onClick={() => handleWidgetClick(stat)}
              style={{ cursor: 'pointer' }}
            >
              <Card.Body className="p-4">
                <div className="d-flex align-items-center">
                  <span
                    className="me-3 d-none d-md-inline-block p-3"
                    style={{
                      backgroundColor: stat.color,
                      color: getTextColor(stat.color),
                      borderRadius: '50%',
                      fontSize: '1.5rem',
                    }}
                  >
                    <FontAwesomeIcon icon={stat.icon} />
                  </span>
                  <div>
                    <p className="mb-1">{stat.name}</p>
                    <h4 className="mb-0">
                      <CountUp end={stat.count} duration={2} />
                    </h4>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>

      {/* Current Meetings per Location as a UL in a Card */}
      <Row className="mt-1">
        <Col md={12}>
          <Card className="shadow mb-4 clickable" style={{ cursor: 'pointer' }}>
            <Card.Header>Current Meetings by Location</Card.Header>
            <Card.Body>
              {Object.keys(currentLocationStats).length > 0 ? (
                <ul className="list-unstyled mb-0">
                  {Object.entries(currentLocationStats).map(([location, obj]) => (
                    <li
                      key={`current-${location}`}
                      className="d-flex justify-content-between align-items-center py-2 border-bottom"
                      onClick={() => handleLocationClick('Current', location, obj.meetings)}
                      style={{ cursor: 'pointer' }}
                    >
                      <span>{location}</span>
                      <span>
                        <CountUp end={obj.count} duration={1.5} />
                      </span>
                    </li>
                  ))}
                </ul>
              ) : (
                <p>No current meetings by location.</p>
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>

      {/* Upcoming Meetings per Location as a UL in a Card */}
      <Row className="mt-1">
        <Col md={12}>
          <Card className="shadow mb-4 clickable" style={{ cursor: 'pointer' }}>
            <Card.Header>Upcoming Meetings by Location</Card.Header>
            <Card.Body>
              {Object.keys(upcomingLocationStats).length > 0 ? (
                <ul className="list-unstyled mb-0">
                  {Object.entries(upcomingLocationStats).map(([location, obj]) => (
                    <li
                      key={`upcoming-${location}`}
                      className="d-flex justify-content-between align-items-center py-2 border-bottom"
                      onClick={() => handleLocationClick('Upcoming', location, obj.meetings)}
                      style={{ cursor: 'pointer' }}
                    >
                      <span>{location}</span>
                      <span>
                        <CountUp end={obj.count} duration={1.5} />
                      </span>
                    </li>
                  ))}
                </ul>
              ) : (
                <p>No upcoming meetings by location.</p>
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>

      {/* Modal Popup for Detailed Meeting Data */}
      <Modal show={modalShow} onHide={() => setModalShow(false)} size="xl">
        <Modal.Header closeButton>
          <Modal.Title>{modalTitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {modalData.length > 0 ? (
            <DataTable
              columns={columns}
              data={modalData}
              pagination
            />
          ) : (
            <p>No meetings to display.</p>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setModalShow(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Statistics;

import React, { useState } from "react";
import { Button, Card, Modal, Form } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrash, faPlus, faClose, faSave } from "@fortawesome/free-solid-svg-icons";
import { wrap } from "framer-motion";

const RequestsTab = ({ requests, addRequest, updateRequest, removeRequest, requestList }) => {
    const [showAddModal, setShowAddModal] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);
    const [selectedRequest, setSelectedRequest] = useState(null);
    const [newRequest, setNewRequest] = useState({
        requestID: 0,
        meetingID: 0,
        details: "",
    });

    // Columns for DataTable
    const columns = [
        {
            name: "Department",
            selector: (row) => row.departmentName,
            sortable: true,
            width: "200px",
        },
        {
            name: "Request Name",
            selector: (row) => row.requestName,
            sortable: true,
        },
        {
            name: "Details",
            selector: (row) => row.details,
            wrap: true,
        },
        {
            name: "Actions",
            cell: (row) => (
                <div className="d-flex gap-2">
                    <Button
                        variant="outline-primary"
                        size="xs"
                        onClick={() => handleEdit(row)}
                    >
                        <FontAwesomeIcon icon={faEdit} />
                    </Button>
                    <Button
                        variant="outline-danger"
                        size="xs"
                        onClick={() => handleRemove(row.requestID)}
                    >
                        <FontAwesomeIcon icon={faTrash} />
                    </Button>
                </div>
            ),
            width: "120px",
        },
    ];

    // Add Request Modal Handlers
    const handleAddRequest = () => {
        addRequest(newRequest);
        setNewRequest({ requestID: 0, meetingID: 0, details: "" });
        setShowAddModal(false);
    };

    // Edit Request Modal Handlers
    const handleEdit = (request) => {
        setSelectedRequest(request);
        setShowEditModal(true);
    };

    const handleUpdateRequest = () => {
        updateRequest(selectedRequest);
        setSelectedRequest(null);
        setShowEditModal(false);
    };

    // Remove Request Handler
    const handleRemove = (requestID) => {
        removeRequest(requestID);
    };

    return (
        <Card className="shadow-sm p-3">
            <div className="d-flex justify-content-between mb-3">
                <h4 className="text-primary">Requests</h4>
                <Button variant="primary" size="sm" onClick={() => setShowAddModal(true)}>
                    <FontAwesomeIcon icon={faPlus} /> Add Request
                </Button>
            </div>
            <DataTable
                columns={columns}
                data={requests}
                pagination
                highlightOnHover
            />

            {/* Add Request Modal */}
            <Modal show={showAddModal} size="lg" onHide={() => setShowAddModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Add Request</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Control
                                type="hidden"
                                value={newRequest.meetingID}
                                onChange={(e) =>
                                    setNewRequest({ ...newRequest, meetingID: e.target.value })
                                }
                                placeholder="Enter Meeting ID"
                            />
                        <Form.Group className="mb-3">
                            <Form.Label>Request ID</Form.Label>
                            {/* Select Request from List */}
                            <Form.Select
                                value={newRequest.requestID}
                                onChange={(e) =>
                                    setNewRequest({ ...newRequest, requestID: e.target.value })
                                }
                            >
                                <option value="">Select Request</option>
                                {requestList.map((request) => (
                                    <option key={request.requestID} value={request.requestID}>
                                        {request.name}
                                    </option>
                                ))}
                            </Form.Select>
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Details</Form.Label>
                            <Form.Control
                                as="textarea"
                                rows={4}
                                value={newRequest.details}
                                onChange={(e) =>
                                    setNewRequest({ ...newRequest, details: e.target.value })
                                }
                                placeholder="Enter Details"
                            />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" size="sm" onClick={() => setShowAddModal(false)}>
                        <FontAwesomeIcon icon={faClose} /> Cancel
                    </Button>
                    <Button variant="primary" size="sm" onClick={handleAddRequest}>
                        <FontAwesomeIcon icon={faSave} /> Add
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* Edit Request Modal */}
            {selectedRequest && (
                <Modal show={showEditModal} onHide={() => setShowEditModal(false)}>
                    <Modal.Header closeButton>
                        <Modal.Title>Edit Request</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form>
                            <Form.Group className="mb-3">
                                <Form.Label>Details</Form.Label>
                                <Form.Control
                                    as="textarea"
                                    rows={3}
                                    value={selectedRequest.details}
                                    onChange={(e) =>
                                        setSelectedRequest({
                                            ...selectedRequest,
                                            details: e.target.value,
                                        })
                                    }
                                    placeholder="Enter Details"
                                />
                            </Form.Group>
                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" size="sm" onClick={() => setShowEditModal(false)}>
                            <FontAwesomeIcon icon={faClose} /> Cancel
                        </Button>
                        <Button variant="primary" size="sm" onClick={handleUpdateRequest}>
                            <FontAwesomeIcon icon={faSave} /> Save Changes
                        </Button>
                    </Modal.Footer>
                </Modal>
            )}
        </Card>
    );
};

export default RequestsTab;

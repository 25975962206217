// InviteCard.jsx
import React from 'react';
import { Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import avatarPlaceholder from "../../../../../images/empty_user.jpg";
import { shortDateFormat, timeFormat, friendlyTimeDifference } from '../../../../../utilities/DateTimeManipulator';

const InviteCard = ({ data, onClick }) => {
  return (
    <Card 
      className="mb-3 shadow-sm" 
      style={{ cursor: 'pointer' }} 
      onClick={() => onClick && onClick(data)}
    >
      <Card.Body>
        {/* Header: Location with colored SVG and Dropdown */}
        {/* <div className="d-flex justify-content-between mb-2 text-body">
            {data.meetingVenueData.locationNameName}
        </div> */}

        {/* Venue Name (if provided) */}
        {data.meetingVenueData.venueName && (
        <div className="text-body">
            <span>{data.meetingVenueData.venueName}</span>
        </div>
        )}

        {/* Title */}
        <p className="font-w600 fs-18 mb-1 mt-1">
          <Link to="#" className="text-black">
            {data.title}
          </Link>
        </p>

        {/* Date Range */}
        <div className="mb-4 text-body">
          <span>
            {shortDateFormat(new Date(data.startDate))} | {timeFormat(new Date(data.startDate))} - {timeFormat(new Date(data.endDate))}
          </span>
        </div>

        {/* Attendees and Due Date */}
        <div className="row justify-content-between align-items-center kanban-user">
          
            <ul className="col-6 users">
                <li className="me-0">
                        <img 
                        src={data.organizerAvatar || avatarPlaceholder} 
                        alt={`${data.organizerName} ${data.organizerSurname}`} 
                        />
                </li>
              {data.meetingAttendees &&
                data.meetingAttendees.slice(0, 5).map((attendee, idx) => (
                  <li key={idx}>
                    <img 
                      src={attendee.avatar || avatarPlaceholder} 
                      alt={`${attendee.name} ${attendee.surname}`} 
                    />
                  </li>
                ))
              }
              {data.meetingAttendees && data.meetingAttendees.length > 5 && (
                <li 
                  className="d-flex align-items-center justify-content-center rounded-circle bg-secondary text-white" 
                  style={{ width: '30px', height: '30px', fontSize: '0.8rem' }}
                >
                  +{data.meetingAttendees.length - 5}
                </li>
              )}
            </ul>
          <div className="col-6 d-flex justify-content-end">
            <span className="fs-12 text-black">
              <i className="far fa-clock me-2"></i>
              {friendlyTimeDifference(new Date(), new Date(data.startDate))}
            </span>
          </div>
        </div>
      </Card.Body>
    </Card>
  );
};

export default InviteCard;



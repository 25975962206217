// InvitesList.jsx
import React from 'react';
import { Card } from 'react-bootstrap';
import Shimmer from '../../../../components/Custom/Loading/Shimmer';
import InviteCard from './InviteCard';

const InvitesList = ({ invites, loading, onRowClick }) => {
  if (loading) {
    return <Shimmer />;
  }

  // Create a sorted copy of invites by startDate (ascending)
  const sortedInvites = [...invites].sort(
    (a, b) => new Date(a.startDate) - new Date(b.startDate)
  );

  return (
    <Card className='p-0'>
      <Card.Header className="mb-2">
        <h3 className="mb-0">Invites</h3>
      </Card.Header>
      <Card.Body className="p-0">
        <div className="d-flex flex-column gap-3">
          {sortedInvites.map((invite) => (
            <div key={invite.meetingID} style={{ maxWidth: '600px' }}>
              <InviteCard data={invite} onClick={onRowClick} />
            </div>
          ))}
        </div>
      </Card.Body>
    </Card>
  );
};

export default InvitesList;

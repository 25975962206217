
// Helper function to pad numbers to 2 digits
const pad = (num) => String(num).padStart(2, '0');

// 1. Numeric Datetime: yyyyMMddHHmmss
export const numericDatetime = (date = new Date()) => {
    return `${date.getFullYear()}${pad(date.getMonth() + 1)}${pad(date.getDate())}${pad(date.getHours())}${pad(date.getMinutes())}${pad(date.getSeconds())}`;
    // Example: 20241024153045
};

export const formatDate = (dateString) => {
    if (!dateString) return 'N/A'; // Handle null or undefined dates
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    return `${year}/${month}/${day} ${hours}:${minutes}`;
  };

// 2. Full DateTime Format: Custom format using tokens
export const fullDateTimeFormat = (date = new Date(), format = 'dd-MM-yyyy HH:mm:ss') => {
    const tokens = {
        yyyy: date.getFullYear(),
        MM: pad(date.getMonth() + 1),
        dd: pad(date.getDate()),
        HH: pad(date.getHours()),
        mm: pad(date.getMinutes()),
        ss: pad(date.getSeconds()),
        tt: date.getHours() >= 12 ? 'PM' : 'AM',
    };
    return format.replace(/yyyy|MM|dd|HH|mm|ss|tt/g, (match) => tokens[match]);
    // Example: 24-10-2024 15:30:45
};

// 3. Get Month and Year: e.g., October 2024
export const getMonthYear = (date = new Date()) => {
    const options = { year: 'numeric', month: 'long' };
    return date.toLocaleDateString(undefined, options);
    // Example: October 2024
};

// 4. Short Date Format: e.g., 24 Oct 2024
export const shortDateFormat = (date = new Date()) => {
    if (!(date instanceof Date)) {
        return ""; // Or a suitable default like "Invalid Date"
      }
    
      const options = { day: 'numeric', month: 'short', year: 'numeric' };
      return date.toLocaleDateString(undefined, options);
    // Example: 24 Oct 2024
};

// 5. Time Format: e.g., 03:30 PM
export const timeFormat = (date = new Date()) => {
    if (!(date instanceof Date)) {  // 1. Check if it's a Date object
        return ""; // 3. Return an empty string or a default value to avoid crashing
      }
    
      const options = { hour: '2-digit', minute: '2-digit', hour12: true };
      return date.toLocaleTimeString(undefined, options);
    // Example: 03:30 PM
};

// 6. ISO String: e.g., 2024-10-24T13:30:45.678Z
export const isoString = (date = new Date()) => {
    return date.toISOString();
    // Example: 2024-10-24T13:30:45.678Z
};

// 7. Start of the Day: Sets time to 00:00:00
export const startOfDay = (date = new Date()) => {
    const start = new Date(date);
    start.setHours(0, 0, 0, 0);
    return start;
    // Example: Thu Oct 24 2024 00:00:00 GMT+0200
};

// 8. End of the Day: Sets time to 23:59:59
export const endOfDay = (date = new Date()) => {
    const end = new Date(date);
    end.setHours(23, 59, 59, 999);
    return end;
    // Example: Thu Oct 24 2024 23:59:59 GMT+0200
};

// 9. Time Difference in Minutes
export const timeDifferenceInMinutes = (start, end) => {
    const diff = Math.abs(end - start) / 1000 / 60;
    return Math.round(diff);
    // Example: 45 (if the difference is 45 minutes)
};

// 10. Add Days to a Date
export const addDays = (date = new Date(), days = 1) => {
    const newDate = new Date(date);
    newDate.setDate(date.getDate() + days);
    return newDate;
    // Example: Adds 1 day to Oct 24, 2024 → Fri Oct 25 2024
};

// 11. Check if Date is in the Past
export const isPastDate = (date) => {
    return date < new Date();
    // Example: true (if the date is before today)
};

// 12. Check if Two Dates are the Same Day
export const isSameDay = (date1, date2) => {
    return (
        date1.getFullYear() === date2.getFullYear() &&
        date1.getMonth() === date2.getMonth() &&
        date1.getDate() === date2.getDate()
    );
    // Example: true (if both dates are Oct 24, 2024)
};

// 13. Get Day of the Week: e.g., Thursday
export const getDayOfWeek = (date = new Date()) => {
    const options = { weekday: 'long' };
    return date.toLocaleDateString(undefined, options);
    // Example: Thursday
};

export const formatTime = (dateTime) => {
    if (!dateTime) return "N/A";
    const date = new Date(dateTime);
    return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
};

export const currentDateTime = new Date()
        .toISOString()
        .replace(/[-:.T]/g, "")
        .slice(0, 14); // Format yyyyMMddHHmmss

// string datetime to formatted datetime string with format options (yyyy-MM-dd HH:mm), default is yyyy-MM-dd HH:mm
export const formattedDateTime = (datetime, format = 'yyyy-MM-dd HH:mm') => {
    if (!datetime) return 'N/A';
    const date = new Date(datetime);
    const tokens = {
        yyyy: date.getFullYear(),
        MM: pad(date.getMonth() + 1),
        dd: pad(date.getDate()),
        HH: pad(date.getHours()),
        mm: pad(date.getMinutes()),
        ss: pad(date.getSeconds()),
        tt: date.getHours() >= 12 ? 'PM' : 'AM',
    };
    return format.replace(/yyyy|MM|dd|HH|mm|ss|tt/g, (match) => tokens[match]);
};

// String datetime to dddd, Do MMMM YYYY format return string
export const formattedDateTimeLong = (datetime) => {
    if (!datetime) return 'N/A';
    const date = new Date(datetime);
    const options = { weekday: 'long', day: 'numeric', month: 'long', year: 'numeric' };
    return date.toLocaleDateString(undefined, options);
};


export const friendlyTimeDifference = (start, end) => {
    if (!(start instanceof Date) || !(end instanceof Date)) {
        return "Invalid Date";
    }

    const diffInMilliseconds = end.getTime() - start.getTime(); // No Math.abs here!

    const seconds = Math.floor(Math.abs(diffInMilliseconds) / 1000); // Use Math.abs for seconds
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);
    const weeks = Math.floor(days / 7);
    const months = Math.floor(days / 30.44);
    const years = Math.floor(days / 365.25);

    const timeDirection = diffInMilliseconds < 0 ? "ago" : "from now"; // Determine direction

    let timeString = "";

    if (years > 0) {
        timeString = `${years} year${years > 1 ? 's' : ''}`;
    } else if (months > 0) {
        timeString = `${months} month${months > 1 ? 's' : ''}`;
    } else if (weeks > 0) {
        timeString = `${weeks} week${weeks > 1 ? 's' : ''}`;
    } else if (days > 0) {
        timeString = `${days} day${days > 1 ? 's' : ''}`;
    } else if (hours > 0) {
        timeString = `${hours} hour${hours > 1 ? 's' : ''}`;
    } else if (minutes > 0) {
        timeString = `${minutes} minute${minutes > 1 ? 's' : ''}`;
    } else if (seconds < 30 && diffInMilliseconds !== 0) { // less than 30 seconds and not the same time
      return "Just now";
    }
    else {
      timeString = `${seconds} second${seconds > 1 ? 's' : ''}`;
    }

    return timeString ? `${timeString} ${timeDirection}` : "Just now";
};


export const friendlyTimeDifferenceOnly = (start, end) => {
    if (!(start instanceof Date) || !(end instanceof Date)) {
        return "Invalid Date";
    }

    const diffInMilliseconds = end.getTime() - start.getTime(); // No Math.abs here!

    const seconds = Math.floor(Math.abs(diffInMilliseconds) / 1000); // Use Math.abs for seconds
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);
    const weeks = Math.floor(days / 7);
    const months = Math.floor(days / 30.44);
    const years = Math.floor(days / 365.25);

    // const timeDirection = diffInMilliseconds < 0 ? "ago" : "from now"; // Determine direction

    let timeString = "";

    if (years > 0) {
        timeString = `${years} year${years > 1 ? 's' : ''}`;
    } else if (months > 0) {
        timeString = `${months} month${months > 1 ? 's' : ''}`;
    } else if (weeks > 0) {
        timeString = `${weeks} week${weeks > 1 ? 's' : ''}`;
    } else if (days > 0) {
        timeString = `${days} day${days > 1 ? 's' : ''}`;
    } else if (hours > 0) {
        timeString = `${hours} hour${hours > 1 ? 's' : ''}`;
    } else if (minutes > 0) {
        timeString = `${minutes} minute${minutes > 1 ? 's' : ''}`;
    } else if (seconds < 30 && diffInMilliseconds !== 0) { // less than 30 seconds and not the same time
      return "Just now";
    }
    else {
      timeString = `${seconds} second${seconds > 1 ? 's' : ''}`;
    }

    return timeString;
};
// src/components/Meetings/Overview/MeetingsOverview.jsx
import React, { Fragment, useState, useEffect } from 'react';
import { Button, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSyncAlt } from '@fortawesome/free-solid-svg-icons';
import Shimmer from '../../../../components/Custom/Loading/Shimmer';

// Import child components (each should be in its own file)
import Statistics from './Statistics';
import PendingMeetings from './PendingMeetings';
import CurrentMeetings from './CurrentMeetings';
import UpcomingMeetings from './UpcomingMeetings';
import MeetingsCalendar from './MeetingsCalendar';

// Adjust the import paths as needed for your API services
import { getFilteredMeetingList } from '../../../../../services/ccms/meetingsManagement';
import { LocationListView } from "../../../../../services/ccms/locationManagement/location/location_endpoints";

const MeetingsOverview = () => {
  const [meetings, setMeetings] = useState([]);
  const [locations, setLocations] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  // Store the selected location ID (as a string or number)
  const [selectedLocationId, setSelectedLocationId] = useState("");

  // Fetch meetings when the component mounts
  const fetchMeetings = async () => {
    setLoading(true);
    try {
      // Fetch up to 1000 meetings; adjust pagination as needed.
      const response = await getFilteredMeetingList({}, 1, 1000);
      setMeetings(response.results || []);
    } catch (err) {
      console.error('Error fetching meetings:', err);
      setError(err);
    }
    setLoading(false);
  };

  // Fetch locations when the component mounts
  const fetchLocations = async () => {
    try {
      const response = await LocationListView();
      setLocations(response.results || []);
    } catch (err) {
      console.error('Error fetching locations:', err);
    }
  };

  useEffect(() => {
    fetchMeetings();
    fetchLocations();
  }, []);

  const handleRefresh = () => {
    fetchMeetings();
  };

  // Filter meetings based on the selected location.
  // For a meeting to be included, its meetingVenueData.venueID must be found
  // in the list of venue IDs for the selected location.
  const filteredMeetings = selectedLocationId
    ? meetings.filter(meeting => {
        if (!meeting.meetingVenueData || !meeting.meetingVenueData.venueID) return false;
        // Find the location object by its LocationID (ensure type match)
        const location = locations.find(
          loc => String(loc.locationID) === String(selectedLocationId)
        );
        if (!location || !location.venues) return false;
        // Extract the venue IDs from the location's Venues list
        const venueIDs = location.venues.map(v => v.venueID);
        return venueIDs.includes(meeting.meetingVenueData.venueID);
      })
    : meetings;

  if (loading) {
    return <Shimmer type="page" />;
  }

  if (error) {
    return <div className="mt-4">Error loading meetings.</div>;
  }

  return (
    <Fragment>
      {/* Header with a location filter dropdown and a refresh button */}
      <div className="d-flex justify-content-between align-items-center mb-4">
        <div>
          <label className="me-2">Filter by Location:</label>
          <select
            className="form-select"
            style={{ width: '250px' }}
            value={selectedLocationId}
            onChange={(e) => setSelectedLocationId(e.target.value)}
          >
            <option value="">All Locations</option>
            {locations.map(loc => (
              <option key={loc.locationID} value={loc.locationID}>
                {loc.name}
              </option>
            ))}
          </select>
        </div>
        <Button size="xs" onClick={handleRefresh}>
          <FontAwesomeIcon icon={faSyncAlt} />
        </Button>
      </div>

      {/* First Row: Statistics and Calendar */}
      <Row>
        <Col sm={12} md={12} lg={12} xl={7}>
          <Statistics meetings={filteredMeetings} />
        </Col>
        <Col sm={12} md={12} lg={12} xl={5}>
          <MeetingsCalendar meetings={filteredMeetings} />
        </Col>
      </Row>

      {/* Second Row: Pending and Current Meetings */}
      <Row className="mt-4">
        <Col md={6}>
          <PendingMeetings meetings={filteredMeetings} />
        </Col>
        <Col md={6}>
          <CurrentMeetings meetings={filteredMeetings} />
        </Col>
      </Row>

      {/* Upcoming Meetings */}
      <UpcomingMeetings meetings={filteredMeetings} />
    </Fragment>
  );
};

export default MeetingsOverview;

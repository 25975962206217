// InviteResponseModal.jsx
import React, { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { shortDateFormat, timeFormat } from '../../../../../utilities/DateTimeManipulator'; // Adjust path as needed

const InviteResponseModal = ({ show, onHide, invite, onAccept, onDecline, userID }) => {
  const [declineMode, setDeclineMode] = useState(false);
  const [declineReason, setDeclineReason] = useState('');

  // Helper function to update the current user's attendee record
  const updateAttendeeStatus = (status, comment = '') => {
    if (!invite || !invite.meetingAttendees) return null;
    // Find the current user's attendee record in the meetingAttendees list
    const attendeeIndex = invite.meetingAttendees.findIndex(
      (attendee) => attendee.UserID === userID
    );
    if (attendeeIndex === -1) return null;

    // Create a copy and update the record
    const updatedAttendee = { ...invite.meetingAttendees[attendeeIndex] };
    updatedAttendee.statusID = status;
    if (status === -1) {
      updatedAttendee.comment = comment;
    }
    return updatedAttendee;
  };

  const handleAccept = () => {
    const updatedAttendee = updateAttendeeStatus(1);
    if (updatedAttendee) {
      onAccept(updatedAttendee);
    }
    // Reset state and close modal
    setDeclineMode(false);
    setDeclineReason('');
    onHide();
  };

  const handleDeclineSubmit = () => {
    const updatedAttendee = updateAttendeeStatus(-1, declineReason);
    if (updatedAttendee) {
      onDecline(updatedAttendee);
    }
    setDeclineMode(false);
    setDeclineReason('');
    onHide();
  };

  const handleCancelDecline = () => {
    // Simply exit decline mode without submitting
    setDeclineMode(false);
    setDeclineReason('');
  };

  return (
    <Modal show={show} onHide={onHide} centered>
      <Modal.Header closeButton>
        <Modal.Title>Meeting Invite</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {/* Invite Details */}
        <h5>{invite.title}</h5>
        {invite.meetingVenueData && invite.meetingVenueData.venueName && (
          <p>
            <strong>Venue:</strong> {invite.meetingVenueData.venueName}
          </p>
        )}
        <p>
          <strong>Date:</strong> {shortDateFormat(new Date(invite.startDate))} |{' '}
          {timeFormat(new Date(invite.startDate))} - {timeFormat(new Date(invite.endDate))}
        </p>
        {!declineMode && (
          <p>Please choose whether to accept or decline this meeting invite.</p>
        )}
        {declineMode && (
          <Form>
            <Form.Group controlId="declineReason">
              <Form.Label>Please provide a reason for declining:</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                value={declineReason}
                onChange={(e) => setDeclineReason(e.target.value)}
                placeholder="Enter your reason..."
              />
            </Form.Group>
          </Form>
        )}
      </Modal.Body>
      <Modal.Footer>
        {declineMode ? (
          <>
            <Button variant="secondary" onClick={handleCancelDecline}>
              Cancel
            </Button>
            <Button
              variant="danger"
              onClick={handleDeclineSubmit}
              disabled={!declineReason.trim()}
            >
              Submit Decline
            </Button>
          </>
        ) : (
          <>
            <Button variant="success" onClick={handleAccept}>
              Accept Meeting
            </Button>
            <Button variant="danger" onClick={() => setDeclineMode(true)}>
              Decline Invite
            </Button>
          </>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default InviteResponseModal;

// MeetingDetailsModal.jsx
import React from 'react';
import { Modal, Button } from 'react-bootstrap';

const MeetingDetailsModal = ({ show, onHide, meeting, onOpenMeeting }) => {
  if (!meeting) return null;

  return (
    <Modal show={show} onHide={onHide} centered>
      <Modal.Header closeButton>
        <Modal.Title>{meeting.Title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          <strong>Description:</strong> {meeting.description}
        </p>
        <p>
          <strong>Start:</strong> {new Date(meeting.startDate).toLocaleString()}
        </p>
        <p>
          <strong>End:</strong> {new Date(meeting.endDate).toLocaleString()}
        </p>
        <p>
          <strong>Organizer:</strong> {meeting.organizerName} {meeting.organizerSurname}
        </p>
        <p>
          <strong>Type:</strong>{' '}
          <span style={{ color: meeting.typeColor }}>{meeting.typeName}</span>
        </p>
        <p>
          <strong>Status:</strong> {meeting.statusName}
        </p>
        {meeting.MeetingVenue && (
          <>
            <p>
              <strong>Venue:</strong> {meeting.meetingVenue.venueName}
            </p>
            <p>
              <strong>Address:</strong> {meeting.meetingVenue.address}
            </p>
          </>
        )}
        {meeting.attendees && meeting.attendees.length > 0 && (
          <div>
            <p>
              <strong>Attendees:</strong>
            </p>
            <ul>
              {meeting.attendees.map((attendee, index) => (
                <li key={index}>
                  {attendee.name} {attendee.surname} - {attendee.email}
                </li>
              ))}
            </ul>
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Close
        </Button>
        <Button variant="primary" onClick={onOpenMeeting}>
          Open Meeting
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default MeetingDetailsModal;

import React, { Fragment, useEffect, useState } from 'react';
import { useLocation, useNavigate } from "react-router-dom";
import { Card, Nav, Tab } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCodePullRequest, faComment, faFile, faGears, faMapLocation, faUserGroup } from '@fortawesome/free-solid-svg-icons';
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

// Parts
import MeetingDetails from './meetingDetails';
import VenueTab from './VenueTab';
import AttendeesTab from './AttendeesTab';
import SetupsTab from './SetupsTab';
import RequestsTab from './RequestsTab';
import CommentsTab from './CommentsTab';
import FilesTab from './FilesTab';

// API Service
import { updateMeetingVenue, saveMeetingAttendee, deleteMeetingAttendee, getMeetingSetupList, 
    getMeetingRequestList, saveSetupInMeeting, saveRequestInMeeting, getMeetingTypeList, updateMeeting } from '../../../../../services/ccms/meetingsManagement';

const MySwal = withReactContent(Swal);

const ViewMeeting = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [isLoaded, setIsLoaded] = useState(false);
    const [meetingData, setMeetingData] = useState({});
    const [setupList, setSetupList] = useState([]);
    const [requestList, setRequestList] = useState([]);
    const [meetingTypes, setMeetingTypes] = useState([]);

    // Fetch location data on mount
    useEffect(() => {
        const fetchLocationData = async () => {
            try {
                const data = location.state;
                if (!data) {
                    navigate("/app/meetings");
                } else {
                    setMeetingData(data);
                    setIsLoaded(true);
                }
            } catch (error) {
                MySwal.fire({
                    icon: "error",
                    title: "Error",
                    text: "Failed to fetch location data.",
                });
            }
        };

        fetchLocationData();
    }, [location.state, navigate]);

    // Fetch lookups
    useEffect(() => {
        const fetchLookups = async () => {
            try {
                const setupList = await getMeetingSetupList();
                if (setupList.status === "Success") {
                    setSetupList(setupList.results);
                }
                const requestList = await getMeetingRequestList();
                if (requestList.status === "Success") {
                    setRequestList(requestList.results);
                }
                const meetingTypes = await getMeetingTypeList();
                if (meetingTypes.status === "Success") {
                    setMeetingTypes(meetingTypes.results);
                }
            } catch (error) {
            console.error("Failed to fetch lookups:", error);
            }
        };

        fetchLookups();
    }, []);



    // Function to update meeting venue
    const handleUpdateMeetingVenue = async (venueData) => {
        try {
            const response = await updateMeetingVenue(venueData);

            if (response.status === "Success") {
                MySwal.fire({
                    icon: "success",
                    title: "Success",
                    text: response.message,
                });
            } else {
                MySwal.fire({
                    icon: "error",
                    title: "Error",
                    text: response.message || "Failed to update venue details.",
                });
            }
        } catch (error) {
            MySwal.fire({
                icon: "error",
                title: "Error",
                text: "An unexpected error occurred while updating venue details.",
            });
        }
    };

    // Add a new attendee
    const handleAddAttendee = async (attendee) => {
        try {
            attendee.meetingID = meetingData.meetingID;
            const response = await saveMeetingAttendee(attendee);

            if (response.status === "Success") {
                // Update the local state using the returned attendee data.
                setMeetingData((prevData) => ({
                    ...prevData,
                    meetingAttendees: [...(prevData.meetingAttendees || []), response.results],
                }));

                MySwal.fire({
                    icon: "success",
                    title: "Attendee Added",
                    text: response.message,
                });
            } else {
                MySwal.fire({
                    icon: "error",
                    title: "Error",
                    text: response.message || "Failed to add attendee.",
                });
            }
        } catch (error) {
            console.error("Failed to add attendee:", error);
            MySwal.fire({
                icon: "error",
                title: "Error",
                text: "An unexpected error occurred while adding the attendee.",
            });
        }
    };

    // Remove an attendee (using UserID as unique key)
    const handleRemoveAttendee = async (attendeeID) => {
        try {
            const response = await deleteMeetingAttendee(attendeeID);

            if (response.status === "Success") {
                setMeetingData((prevData) => ({
                    ...prevData,
                    meetingAttendees: (prevData.meetingAttendees || []).filter(
                        (attendee) => attendee.UserID !== attendeeID
                    ),
                }));

                MySwal.fire({
                    icon: "success",
                    title: "Attendee Removed",
                    text: response.message,
                });
            } else {
                MySwal.fire({
                    icon: "error",
                    title: "Error",
                    text: response.message || "Failed to remove attendee.",
                });
            }
        } catch (error) {
            console.error("Failed to remove attendee:", error);
            MySwal.fire({
                icon: "error",
                title: "Error",
                text: "An unexpected error occurred while removing the attendee.",
            });
        }
    };

    const handleAddSetup = async (setup) => {
        try {
            setup.meetingID = meetingData.meetingID;
            const response = await saveSetupInMeeting(setup);
            if (response.status === "Success") {
                setMeetingData((prevData) => ({
                    ...prevData,
                    meetingSetups: [...(prevData.meetingSetups || []), response.results],
                }));

                MySwal.fire({
                    icon: "success",
                    title: "Setup Added",
                    text: response.message,
                });
            } else {
                MySwal.fire({
                    icon: "error",
                    title: "Error",
                    text: response.message || "Failed to add setup.",
                });
            }
        }
        catch (error) {
            console.error("Failed to add setup:", error);
            MySwal.fire({
                icon: "error",
                title: "Error",
                text: "An unexpected error occurred while adding the setup.",
            });
        }
    };
    
    
    const handleUpdateSetup = (updatedSetup) => {
        setMeetingData((prevData) => ({
            ...prevData,
            setups: prevData.setups.map((setup) =>
                setup.setupID === updatedSetup.setupID ? updatedSetup : setup
            ),
        }));
    };
    
    const handleRemoveSetup = (setupID) => {
        setMeetingData((prevData) => ({
            ...prevData,
            setups: prevData.setups.filter((setup) => setup.setupID !== setupID),
        }));
    };

    const handleAddRequest = async (request) => {
        try {
            request.meetingID = meetingData.meetingID;
            const response = await saveRequestInMeeting(request);
            if (response.status === "Success") {
                setMeetingData((prevData) => ({
                    ...prevData,
                    meetingRequests: [...(prevData.meetingRequests || []), response.results],
                }));

                MySwal.fire({
                    icon: "success",
                    title: "Request Added",
                    text: response.message,
                });
            } else {
                MySwal.fire({
                    icon: "error",
                    title: "Error",
                    text: response.message || "Failed to add request.",
                });
            }
        } catch (error) {
            console.error("Failed to add request:", error);
            MySwal.fire({
                icon: "error",
                title: "Error",
                text: "An unexpected error occurred while adding the request.",
            });
        }
    };
    
    const handleUpdateRequest = (updatedRequest) => {
        setMeetingData((prevData) => ({
            ...prevData,
            requests: prevData.requests.map((request) =>
                request.requestID === updatedRequest.requestID ? updatedRequest : request
            ),
        }));
    };
    
    const handleRemoveRequest = (requestID) => {
        setMeetingData((prevData) => ({
            ...prevData,
            requests: prevData.requests.filter((request) => request.requestID !== requestID),
        }));
    };
    

    const handleUpdateMeeting = async (updatedMeetingData) => {
        try {
          const response = await updateMeeting(updatedMeetingData);
          if (response.status === "Success") {
            // Merge the returned meeting data into your current state.
            // If your MeetingViewModel holds extra properties (such as MeetingVenueData, etc.)
            // that are not returned by the API, you might want to preserve them.
            setMeetingData((prevData) => ({
              ...prevData,
              ...response.results,
              // Uncomment the following lines if you want to preserve existing extra properties:
              // MeetingVenueData: prevData.MeetingVenueData,
              // MeetingSetups: prevData.MeetingSetups,
              // MeetingRequests: prevData.MeetingRequests,
              // MeetingAttendees: prevData.MeetingAttendees,
            }));
      
            MySwal.fire({
              icon: "success",
              title: "Success",
              text: response.message,
            });
          } else {
            MySwal.fire({
              icon: "error",
              title: "Error",
              text: response.message || "Failed to update meeting details.",
            });
          }
        } catch (error) {
          MySwal.fire({
            icon: "error",
            title: "Error",
            text: "An unexpected error occurred while updating meeting details.",
          });
        }
      };
      

    const handleBackButton = () => {
        navigate("/app/meetings?refresh");
    };

    return (
        <Fragment>
            <MeetingDetails meetingData={meetingData} isLoading={!isLoaded} handleBackButton={handleBackButton} meetingTypes={meetingTypes} onUpdate={handleUpdateMeeting} />
            <Card className="shadow-sm mb-3 p-3" style={{ minHeight: "30em" }}>
                <div className="profile-tab">
                    <Tab.Container defaultActiveKey="venue">
                        <Nav as="ul" variant="pills" className="nav nav-tabs">
                            <Nav.Item as="li" className="nav-item">
                                <Nav.Link eventKey="venue" className="nav-link">
                                    <FontAwesomeIcon icon={faMapLocation} /> Venue
                                </Nav.Link>
                            </Nav.Item>
                            <Nav.Item as="li" className="nav-item">
                                <Nav.Link eventKey="attendees" className="nav-link">
                                    <FontAwesomeIcon icon={faUserGroup} /> Attendees
                                </Nav.Link>
                            </Nav.Item>
                            <Nav.Item as="li" className="nav-item">
                                <Nav.Link eventKey="setups" className="nav-link">
                                    <FontAwesomeIcon icon={faGears} /> Setups
                                </Nav.Link>
                            </Nav.Item>
                            <Nav.Item as="li" className="nav-item">
                                <Nav.Link eventKey="requests" className="nav-link">
                                    <FontAwesomeIcon icon={faCodePullRequest} /> Requests
                                </Nav.Link>
                            </Nav.Item>
                            <Nav.Item as="li" className="nav-item">
                                <Nav.Link eventKey="comments" className="nav-link">
                                    <FontAwesomeIcon icon={faComment} /> Comments
                                </Nav.Link>
                            </Nav.Item>
                            <Nav.Item as="li" className="nav-item">
                                <Nav.Link eventKey="files" className="nav-link">
                                    <FontAwesomeIcon icon={faFile} /> Files
                                </Nav.Link>
                            </Nav.Item>
                        </Nav>
                        <Tab.Content className="pt-3">
                            <Tab.Pane eventKey="venue">
                                <VenueTab
                                    meetingData={meetingData.meetingVenueData}
                                    isLoading={!isLoaded}
                                    updateMeetingVenue={handleUpdateMeetingVenue}
                                />
                            </Tab.Pane>
                            <Tab.Pane eventKey="attendees">
                                <AttendeesTab 
                                    attendees={meetingData.meetingAttendees || []} 
                                    addAttendee={handleAddAttendee} 
                                />
                            </Tab.Pane>
                            <Tab.Pane eventKey="setups">
                                <SetupsTab
                                    setups={meetingData.meetingSetups || []}
                                    addSetup={handleAddSetup}
                                    updateSetup={handleUpdateSetup}
                                    removeSetup={handleRemoveSetup}
                                    setupList={setupList}
                                />
                            </Tab.Pane>
                            <Tab.Pane eventKey="requests">
                                <RequestsTab
                                    requests={meetingData.meetingRequests || []}
                                    addRequest={handleAddRequest}
                                    updateRequest={handleUpdateRequest}
                                    removeRequest={handleRemoveRequest}
                                    requestList={requestList}
                                />
                            </Tab.Pane>
                            <Tab.Pane eventKey="comments">
                                <CommentsTab meetingID={meetingData.meetingID} />
                            </Tab.Pane>
                            <Tab.Pane eventKey="files">
                                <FilesTab meetingID={meetingData.meetingID} />
                            </Tab.Pane>
                        </Tab.Content>
                    </Tab.Container>
                </div>
            </Card>
        </Fragment>
    );
};

export default ViewMeeting;

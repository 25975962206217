import React, { Fragment, useState, useEffect, useContext } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useNavigate, useLocation } from 'react-router-dom';
import { UserRightsContext } from '../../../../../context/UserRightsContext';
import { useMediaQuery } from 'react-responsive';

// API Services
import { getInvitedMeetingList, getAttendingMeetingList, MeetingAttendanceResponse } from '../../../../../services/ccms/meetingsManagement';

// Child components
import InvitesList from './InvitesList';
import MeetingCalendar from './MeetingCalendar';
import MeetingDetailsModal from './MeetingDetailsModal';
import InviteResponseModal from './InviteResponseModal';

const MyMeetings = () => {
  const navigate = useNavigate();
  const location = useLocation(); // Track navigation changes
  const { userRights } = useContext(UserRightsContext);
  const isMobile = useMediaQuery({ maxWidth: 767 });

  const [invites, setInvites] = useState([]);
  const [meetings, setMeetings] = useState([]);
  const [loading, setLoading] = useState(true);
  const [calendarView, setCalendarView] = useState('week');
  const [modalShow, setModalShow] = useState(false);
  const [selectedMeeting, setSelectedMeeting] = useState(null);
  const [isInvite, setIsInvite] = useState(false);
  const [userDetails, setUserDetails] = useState(null);
  const [inviteSize, setInviteSize] = useState(3);
  const [meetingSize, setMeetingSize] = useState(9);
  const [refreshTrigger, setRefreshTrigger] = useState(false); // Used for re-fetching data

  // Retrieve stored user details
  useEffect(() => {
    const storedUserDetails = JSON.parse(localStorage.getItem('userDetails'));
    setUserDetails(storedUserDetails);
  }, []);

  // Fetch invites and meetings when component mounts or navigation changes
  useEffect(() => {
    if (!userDetails) return;

    const fetchData = async () => {
      setLoading(true);
      try {
        const invitedData = await getInvitedMeetingList(true);
        if (invitedData.status === 'Success') {
          setInvites(invitedData.results);
          setMeetingSize(invitedData.results.length > 0 ? 9 : 12);
          setInviteSize(invitedData.results.length > 0 ? 3 : 0);
        } else {
          setInvites([]);
        }

        const userMeetingsData = await getAttendingMeetingList(userDetails.userId, true);
        if (userMeetingsData.status === 'Success') {
          setMeetings(userMeetingsData.results);
        } else {
          setMeetings([]);
        }
      } catch (error) {
        console.error('Error fetching meetings:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [userDetails, location.pathname, refreshTrigger]); // Refresh on navigation or refresh trigger

  // Refresh meetings manually (used in MeetingCalendar)
  const handleRefreshMeetings = () => {
    setRefreshTrigger((prev) => !prev);
  };

  // When a meeting (or invite) is clicked, open the appropriate modal.
  const handleEventClick = (meeting) => {
    setSelectedMeeting(meeting);
    setIsInvite(false);
    setModalShow(true);
  };

  const handleRowClick = (meeting) => {
    setSelectedMeeting(meeting);
    setIsInvite(true);
    setModalShow(true);
  };

  const handleModalClose = () => {
    setModalShow(false);
    // setSelectedMeeting(null);
  };

  const handleOpenMeeting = () => {
    if (selectedMeeting) {
      navigate('/app/meetings/view-meeting', { state: selectedMeeting });
    }
  };

  // Accept invite and refresh meetings on success
  const handleAcceptInvite = async (invite) => {
    try {
      const response = await MeetingAttendanceResponse(invite);
      if (response.status === 'Success') {
        // console.log("Meeting accepted successfully!");
        handleRefreshMeetings(); // ✅ Trigger refresh on success
      } else {
        console.error("Error accepting meeting:", response.message);
      }
      setModalShow(false);
    } catch (error) {
      console.error("Error accepting meeting:", error);
    } finally {
    }
  };

  // Decline invite and refresh meetings on success
  const handleDeclineInvite = async (invite, reason) => {
    try {
      const response = await MeetingAttendanceResponse(invite);
      if (response.status === 'Success') {
        console.log("Meeting declined successfully!");
        handleRefreshMeetings(); // ✅ Trigger refresh on success
      } else {
        console.error("Error declining meeting:", response.message);
      }
      setModalShow(false);
    } catch (error) {
      console.error("Error declining meeting:", error);
    } finally {
      // setModalShow(false);
    }
  };

  return (
    <Fragment>
      <Row className="mt-3">
        <Col md={inviteSize || 3} style={{ display: invites.length > 0 ? 'block' : 'none' }}>
          <InvitesList invites={invites} loading={loading} onRowClick={handleRowClick} />
        </Col>

        <Col md={meetingSize || 9}>
          <MeetingCalendar
            meetings={meetings}
            loading={loading}
            calendarView={calendarView}
            onEventClick={handleEventClick}
            onMeetingRefresh={handleRefreshMeetings} // ✅ Ensuring this remains
          />
        </Col>
      </Row>

      {isInvite ? (
        <InviteResponseModal
          show={modalShow}
          invite={selectedMeeting}
          onHide={handleModalClose}
          onAccept={handleAcceptInvite}
          onDecline={handleDeclineInvite}
          userID={userDetails?.userID}
        />
      ) : (
        <MeetingDetailsModal
          show={modalShow}
          meeting={selectedMeeting}
          onHide={handleModalClose}
          onOpenMeeting={handleOpenMeeting}
        />
      )}
    </Fragment>
  );
};

export default MyMeetings;

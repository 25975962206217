// EditMeetingModal.js
import React, { useEffect, useState } from 'react';
import { Button, Modal, Form, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave, faTimes } from '@fortawesome/free-solid-svg-icons';

const EditMeetingModal = ({ show, onClose, onSave, meeting, typeList }) => {
  // Initialize form state with the current meeting data
  const [formData, setFormData] = useState({ ...meeting });

  // Update local form state if the meeting prop changes
  useEffect(() => {
    setFormData({ ...meeting });
  }, [meeting]);

  // Helper to convert a date value to a value suitable for a datetime-local input
  const toDateTimeLocal = (dateValue) => {
    if (!dateValue) return '';
    // Create a new Date from the provided value and return the ISO string trimmed to "yyyy-MM-ddThh:mm"
    return new Date(dateValue).toISOString().substring(0, 16);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevData => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Optionally add validation logic here
    onSave(formData);
  };

  return (
    <Modal show={show} onHide={onClose} backdrop="static" size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Edit Meeting</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          {/* Title */}
          <Form.Group as={Row} className="mb-3" controlId="editMeetingTitle">
            <Form.Label column sm={3}>Title</Form.Label>
            <Col sm={9}>
              <Form.Control
                type="text"
                name="title"
                value={formData.title || ''}
                onChange={handleChange}
                required
              />
            </Col>
          </Form.Group>

          {/* Description */}
          <Form.Group as={Row} className="mb-3" controlId="editMeetingDescription">
            <Form.Label column sm={3}>Description</Form.Label>
            <Col sm={9}>
              <Form.Control
                as="textarea"
                name="description"
                value={formData.description || ''}
                onChange={handleChange}
                rows={3}
              />
            </Col>
          </Form.Group>

          {/* Meeting Type */}
          <Form.Group as={Row} className="mb-3" controlId="editMeetingType">
            <Form.Label column sm={3}>Type</Form.Label>
            <Col sm={9}>
              <Form.Select
                name="typeID"
                value={formData.typeID || ''}
                onChange={handleChange}
                required
              >
                <option value="">Select Type</option>
                {typeList.map((type) => (
                  <option key={type.typeID} value={type.typeID}>
                    {type.name}
                  </option>
                ))}
              </Form.Select>
            </Col>
          </Form.Group>

          {/* Start Date */}
          <Form.Group as={Row} className="mb-3" controlId="editMeetingStartDate">
            <Form.Label column sm={3}>Start Date</Form.Label>
            <Col sm={9}>
              <Form.Control
                type="datetime-local"
                name="startDate"
                value={toDateTimeLocal(formData.startDate)}
                onChange={handleChange}
                required
              />
            </Col>
          </Form.Group>

          {/* End Date */}
          <Form.Group as={Row} className="mb-3" controlId="editMeetingEndDate">
            <Form.Label column sm={3}>End Date</Form.Label>
            <Col sm={9}>
              <Form.Control
                type="datetime-local"
                name="endDate"
                value={toDateTimeLocal(formData.endDate)}
                onChange={handleChange}
                required
              />
            </Col>
          </Form.Group>

          {/* Setup Date (optional) */}
          <Form.Group as={Row} className="mb-3" controlId="editMeetingSetupDate">
            <Form.Label column sm={3}>Setup Date</Form.Label>
            <Col sm={9}>
              <Form.Control
                type="datetime-local"
                name="setupDate"
                value={toDateTimeLocal(formData.setupDate)}
                onChange={handleChange}
              />
            </Col>
          </Form.Group>

          {/* Cleanup Date (optional) */}
          <Form.Group as={Row} className="mb-3" controlId="editMeetingCleanupDate">
            <Form.Label column sm={3}>Cleanup Date</Form.Label>
            <Col sm={9}>
              <Form.Control
                type="datetime-local"
                name="cleanupDate"
                value={toDateTimeLocal(formData.cleanupDate)}
                onChange={handleChange}
              />
            </Col>
          </Form.Group>

          {/* Hidden Fields */}
          <Form.Control type="hidden" name="meetingID" value={formData.meetingID || ''} />
          <Form.Control type="hidden" name="statusID" value={formData.statusID || ''} />
          <Form.Control type="hidden" name="organizerID" value={formData.organizerID || ''} />
          <Form.Control type="hidden" name="noOfAttendees" value={formData.noOfAttendees || ''} />
          <Form.Control type="hidden" name="softDelete" value={formData.softDelete ? "true" : "false"} />
          <Form.Control type="hidden" name="createdDateTime" value={formData.createdDateTime || ''} />
          <Form.Control type="hidden" name="modifiedDateTime" value={formData.modifiedDateTime || ''} />

          <div className="text-end">
            <Button variant="secondary" onClick={onClose} className="me-2">
              <FontAwesomeIcon icon={faTimes} /> Cancel
            </Button>
            <Button variant="primary" type="submit">
              <FontAwesomeIcon icon={faSave} /> Save
            </Button>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default EditMeetingModal;

// MeetingDetails.js
import React, { Fragment, useState } from 'react';
import { Button, Card } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt, faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { formattedDateTime, formattedDateTimeLong } from '../../../../../utilities/DateTimeManipulator';
import profile from "../../../../../images/empty_user.jpg";
import { faList, faHeartPulse } from '@fortawesome/free-solid-svg-icons';
import EditMeetingModal from './EditMeetingModal'; // Import the modal component

const MeetingDetails = ({ meetingData, isLoading, onUpdate, handleBackButton, meetingTypes }) => {
  const [showEditModal, setShowEditModal] = useState(false);

  const handleEditClick = () => {
    setShowEditModal(true);
  };

  const handleModalClose = () => {
    setShowEditModal(false);
  };

  // When the modal is saved, update the meeting (either via onUpdate callback or local state)
  const handleModalSave = (updatedMeeting) => {
    if (onUpdate) {
      onUpdate(updatedMeeting);
    }
    // You could also update local state here if meetingData is managed internally
    // For example, if you lifted meetingData into state in this component,
    // you would update it with the new values.
    setShowEditModal(false);
  };

  return (
    <Fragment>
      <Card className="shadow-sm mb-3 p-3">
        <div className="d-flex justify-content-between align-items-top">
          <div>
            <div className="d-flex flex-row align-items-center mb-2">
              <Button variant="outline-primary" size="xs" className="me-2" onClick={handleBackButton}>
                <FontAwesomeIcon icon={faArrowLeft} />
              </Button>
              <h1 className="text-primary">{meetingData.title}</h1>
            </div>
            {meetingData.createdDate ? (
              <span className="mb-2 d-block">
                Created on {formattedDateTime(meetingData.createdDate)}
              </span>
            ) : (
              ''
            )}
          </div>
          <div>
            <Button variant="primary" size="xs" className="mr-2" onClick={handleEditClick}>
              <FontAwesomeIcon icon={faPencilAlt} /> Edit
            </Button>
          </div>
        </div>
        <hr className="text-primary" />
        <div className="border p-3 rounded shadow-sm mb-3">
          <span className="text-muted">Description</span>
          <div>
            <p className="fs-18 font-w500">
              {meetingData.description && meetingData.description !== ''
                ? meetingData.description
                : '...'}
            </p>
          </div>
        </div>
        <div className="d-flex flex-row flex-wrap align-items-center">
          <div className="d-flex align-self-stretch me-2 rounded shadow-sm mb-3 p-3 border border-info">
            <span>
              <i className="far fa-calendar-check scale5 text-primary mt-2 me-3"></i>
            </span>
            <div>
              <h4 className="fs-18 font-w500 text-black">Date</h4>
              <span>
                {meetingData.startDate
                  ? formattedDateTimeLong(meetingData.startDate, 'yyyy-MM-dd')
                  : ''}
              </span>
            </div>
          </div>
          <div className="d-flex me-2 rounded shadow-sm mb-3 p-3 border border-info">
            <span>
              <i className="far fa-clock scale5 text-primary mt-2 me-3"></i>
            </span>
            <div>
              <h4 className="fs-18 font-w500 text-black">Times</h4>
              <span>
                From <strong>{meetingData.startDate ? formattedDateTime(meetingData.startDate, 'HH:mm') : ''}</strong> to{' '}
                <strong>{meetingData.endDate ? formattedDateTime(meetingData.endDate, 'HH:mm') : ''}</strong>
              </span>
            </div>
          </div>
          {meetingData.setupDate && meetingData.setupDate !== '' ? (
            <div className="d-flex me-2 rounded shadow-sm mb-3 p-3 border border-warning">
              <span>
                <i className="far fa-calendar-plus scale5 text-primary mt-2 me-3"></i>
              </span>
              <div>
                <h4 className="fs-18 font-w500 text-black">Setup Date</h4>
                <span>
                  {meetingData.setupDate ? formattedDateTimeLong(meetingData.setupDate) : ''}
                </span>
              </div>
            </div>
          ) : (
            ''
          )}
          {meetingData.cleanupDate && meetingData.cleanupDate !== '' ? (
            <div className="d-flex me-2 rounded shadow-sm mb-3 p-3 border border-danger">
              <span>
                <i className="far fa-calendar-minus scale5 text-primary mt-2 me-3"></i>
              </span>
              <div>
                <h4 className="fs-18 font-w500 text-black">Clean Date</h4>
                <span>
                  {meetingData.cleanupDate ? formattedDateTimeLong(meetingData.cleanupDate) : ''}
                </span>
              </div>
            </div>
          ) : (
            ''
          )}
          {meetingData.organizerName && meetingData.organizerName !== '' ? (
            <div
              className="d-flex align-items-center me-2 rounded shadow-sm mb-3 p-3 border border-primary"
              style={{ minWidth: '15em' }}
            >
              <img
                src={meetingData.organizerAvatar || profile}
                alt="Profile"
                className="rounded-circle me-2"
                width="50"
                height="50"
              />
              <div>
                <h4 className="fs-18 font-w500 text-black">Organizer</h4>
                <span>
                  {meetingData.organizerName} {meetingData.organizerSurname}
                </span>
              </div>
            </div>
          ) : (
            ''
          )}
          <div
            className="d-flex me-2 rounded shadow-sm mb-3 p-3"
            style={{ backgroundColor: meetingData.typeColor, minWidth: '15em' }}
          >
            <span>
              <FontAwesomeIcon icon={faList} className="scale5 mt-2 me-3" />
            </span>
            <div>
              <h4 className="fs-18 font-w500 text-black">Type</h4>
              <span>{meetingData.typeName}</span>
            </div>
          </div>
          <div
            className="d-flex me-2 rounded shadow-sm mb-3 p-3"
            style={{ backgroundColor: meetingData.statusColor, minWidth: '15em' }}
          >
            <span>
              <FontAwesomeIcon icon={faHeartPulse} className="scale5 mt-2 me-3" />
            </span>
            <div>
              <h4 className="fs-18 font-w500 text-black">Status</h4>
              <span>{meetingData.statusName}</span>
            </div>
          </div>
        </div>
      </Card>
      {/* Render the EditMeetingModal */}
      <EditMeetingModal
        show={showEditModal}
        onClose={handleModalClose}
        onSave={handleModalSave}
        meeting={meetingData}
        typeList={meetingTypes}
      />
    </Fragment>
  );
};

export default MeetingDetails;

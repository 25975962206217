// src/components/Meetings/Overview/MeetingsCalendar.jsx
import React, { useState, useEffect } from 'react';
import { Card, Modal, Button } from 'react-bootstrap';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import DataTable from 'react-data-table-component';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import { TextColor } from '../../../../../utilities/colorUtils'; // adjust path as needed
import '@fullcalendar/common/main.css';
import '@fullcalendar/daygrid/main.css';

const MeetingsCalendar = ({ meetings }) => {
  const navigate = useNavigate();
  const isMobile = useMediaQuery({ maxWidth: 767 });
  
  // State for mapping dates (YYYY-MM-DD) to meeting counts
  const [meetingsByDate, setMeetingsByDate] = useState({});
  // Modal state: selected date and meetings for that date
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedMeetings, setSelectedMeetings] = useState([]);
  const [showModal, setShowModal] = useState(false);

  // Compute the counts by date using moment for consistent formatting.
  useEffect(() => {
    const counts = meetings.reduce((acc, meeting) => {
      if (meeting.startDate) {
        const dateStr = moment(meeting.startDate).format('YYYY-MM-DD');
        acc[dateStr] = (acc[dateStr] || 0) + 1;
      }
      return acc;
    }, {});
    setMeetingsByDate(counts);
  }, [meetings]);

  // Custom renderer for day cells.
  // We place the meeting count badge in the top-right corner so it doesn't cover the day number.
  const renderDayCellContent = (arg) => {
    const dateStr = moment(arg.date).format('YYYY-MM-DD');
    const count = meetingsByDate[dateStr] || 0;
    return (
      <div style={{ position: 'relative', height: '100%', width: '100%' }}>
        {/* Day number */}
        <div>{arg.dayNumberText}</div>
        {/* Meeting count badge in the top-right */}
        {count > 0 && (
          <div
            style={{
              position: 'absolute',
              marginTop: '1em',
              marginLeft: '-2em',
              fontSize: '0.8em',
              color: 'white',
              background: 'red',
              padding: '2px 6px',
              borderRadius: '50%',
              fontWeight: 'bold',
              zIndex: 10,
            }}
          >
            {count}
          </div>
        )}
      </div>
    );
  };

  // When a day cell is clicked, filter meetings for that day and show the modal.
  const handleDateClick = (arg) => {
    const dateStr = moment(arg.date).format('YYYY-MM-DD');
    const meetingsForDate = meetings.filter((meeting) => {
      if (!meeting.startDate) return false;
      const mDateStr = moment(meeting.startDate).format('YYYY-MM-DD');
      return mDateStr === dateStr;
    });
    setSelectedDate(dateStr);
    setSelectedMeetings(meetingsForDate);
    setShowModal(true);
  };

  // Define a function to open a meeting detail (from the popup table)
  const handleOpenMeeting = (meeting) => {
    navigate('/app/meetings/view-meeting', { state: meeting });
  };

  // Define columns for the popup DataTable using your provided column definitions.
  const columns = [
    // {
    //   name: 'ID',
    //   selector: row => row.meetingID,
    //   sortable: true,
    //   width: '5em',
    // },
    {
      name: 'Title',
      selector: row => row.title,
      sortable: true,
      width: isMobile ? 'auto' : '20%',
    },
    {
      name: 'Date',
      selector: row => row.startDate ? row.startDate.slice(0, 10) : '',
      sortable: true,
    },
    {
      name: 'Start Time',
      selector: row => row.startDate ? row.startDate.split('T')[1] : '',
      sortable: true,
    },
    {
      name: 'End Time',
      selector: row => row.endDate ? row.endDate.split('T')[1] : '',
      sortable: true,
    },
    {
      name: 'Status',
      selector: row => (
        <span className='badge' style={{ backgroundColor: row.statusColor }}>
          <TextColor bgColor={row.statusColor} text={row.statusName} />
        </span>
      ),
      sortable: true,
    },
    {
      name: 'Type',
      selector: row => (
        <span className='badge' style={{ backgroundColor: row.typeColor }}>
          <TextColor bgColor={row.typeColor} text={row.typeName} />
        </span>
      ),
      sortable: true,
    },
    {
      name: 'Organizer',
      selector: row => `${row.organizerName} ${row.organizerSurname}`,
      sortable: true,
    },
    {
      name: '',
      cell: row => (
        <Button variant="outline-primary" size="xxs" onClick={() => handleOpenMeeting(row)}>
          <i className='fas fa-eye'></i> View
        </Button>
      ),
      ignoreRowClick: true,
      width: '10em',
    },
  ];

  // We pass an empty events array to FullCalendar since we're using custom day cells.
  const events = [];

  return (
    <>
      <Card className="shadow" style={{ height: '600px' }}>
        <Card.Body style={{ height: '100%' }}>
          <FullCalendar
            plugins={[dayGridPlugin, interactionPlugin]}
            initialView="dayGridMonth"
            headerToolbar={{
              left: 'prev,next today',
              center: 'title',
              right: 'dayGridMonth'
            }}
            height="100%"
            dayCellContent={renderDayCellContent}
            dateClick={handleDateClick}
            events={events}
            eventContent={() => null} // Do not render any event blocks
            style={{ height: '100%', width: '100%' }}
          />
        </Card.Body>
      </Card>

      {/* Modal Popup */}
      <Modal show={showModal} onHide={() => setShowModal(false)} size="xl">
        <Modal.Header closeButton>
          <Modal.Title>Meetings on {selectedDate}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedMeetings.length > 0 ? (
            <DataTable
              columns={columns}
              data={selectedMeetings}
              pagination
            />
          ) : (
            <p>No meetings on this day.</p>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default MeetingsCalendar;

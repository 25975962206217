// src/components/Meetings/Overview/UpcomingMeetings.jsx
import React from 'react';
import { Card, Button } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import { friendlyTimeDifferenceOnly } from '../../../../../utilities/DateTimeManipulator';
import { TextColor } from '../../../../../utilities/colorUtils';

const UpcomingMeetings = ({ meetings }) => {
  const navigate = useNavigate();
  const now = new Date();
  const upcomingMeetings = meetings.filter(m => {
    const start = new Date(m.startDate);
    return start > now;
  });

  const columns = [
    { name: 'ID', selector: row => row.meetingID, sortable: true, width: '60px' },
    { name: 'Title', selector: row => row.title, sortable: true },
    { name: 'Start Date', selector: row => row.startDate ? row.startDate.slice(0, 10) : '', sortable: true },
    { name: 'Times', selector: row => row.startDate ? new Date(row.startDate).toLocaleTimeString() + ' - ' + new Date(row.endDate).toLocaleTimeString() : '', sortable: true },
    { name: 'Duration', selector: row => row.startDate ? friendlyTimeDifferenceOnly(new Date(row.startDate), new Date(row.endDate)) : '', sortable: true },
    { name: 'Type', selector: row => row.typeName, sortable: true },
    {
          name: 'Status',
          selector: row => (
            <span className='badge' style={{ backgroundColor: row.statusColor }}>
              <TextColor bgColor={row.statusColor} text={row.statusName} />
            </span>
          ),
          sortable: true,
        },
    { name: 'Organizer', selector: row => `${row.organizerName} ${row.organizerSurname}`, sortable: true },
    {
      name: 'Actions',
      cell: row => (
        <Button variant="outline-primary" size="xxs" onClick={() => navigate('/app/meetings/view-meeting', { state: row })}>
          <FontAwesomeIcon icon={faEye} /> View
        </Button>
      ),
      ignoreRowClick: true,
      button: true,
    },
  ];

  return (
    <Card className="shadow mb-4">
      <Card.Header>Upcoming Meetings (Total: {upcomingMeetings.length})</Card.Header>
      <Card.Body>
        {upcomingMeetings.length > 0 ? (
          <DataTable
            columns={columns}
            data={upcomingMeetings}
            pagination
            highlightOnHover
          />
        ) : (
          <p>No upcoming meetings.</p>
        )}
      </Card.Body>
    </Card>
  );
};

export default UpcomingMeetings;

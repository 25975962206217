// MeetingCalendar.jsx
import React from 'react';
import { Card } from 'react-bootstrap';
import Shimmer from '../../../../components/Custom/Loading/Shimmer';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import interactionPlugin from '@fullcalendar/interaction';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSyncAlt } from '@fortawesome/free-solid-svg-icons';

const MeetingCalendar = ({
  meetings,
  loading,
  calendarView,
  onEventClick,
  onMeetingRefresh
}) => {
  // Map your meeting model to FullCalendar's event shape.
  const calendarEvents = meetings.map(meeting => ({
    id: meeting.meetingID,
    title: meeting.title,
    start: meeting.startDate,
    end: meeting.endDate,
    extendedProps: meeting // store additional properties for later use
  }));

  // Map our simple view names to FullCalendar view names.
  const calendarViewMapping = {
    day: 'timeGridDay',
    week: 'timeGridWeek',
    month: 'dayGridMonth',
  };

  return (
    <Card>
      <Card.Body>
        {loading ? (
          <Shimmer />
        ) : (
          <FullCalendar
            key={calendarView} // re-render when view changes
            plugins={[
              dayGridPlugin,
              timeGridPlugin,
              listPlugin,
              interactionPlugin
            ]}
            initialView={calendarViewMapping[calendarView]}
            headerToolbar={{
              left: 'prev,next today',
              center: 'title',
              right: 'dayGridMonth,timeGridWeek,timeGridDay,refresh'
            }}
            // Customize button text labels
            buttonText={{
              today: 'Today',
              month: 'Month',
              week: 'Week',
              day: 'Day'
            }}
            customButtons={{
              refresh: {
                text: <FontAwesomeIcon icon={faSyncAlt} />,
                click: () => onMeetingRefresh()
              }
            }}
            // Configure per-view title formatting using the "views" option.
            views={{
              dayGridMonth: {
                titleFormat: { year: 'numeric', month: 'long' }
              },
              timeGridWeek: {
                titleFormat: { year: 'numeric', month: 'short', day: 'numeric', weekday: 'short' }
              },
              timeGridDay: {
                titleFormat: { year: 'numeric', month: 'short', day: 'numeric', weekday: 'long' }
              }
            }}
            // Limit the visible times in timeGrid views to working hours.
            slotMinTime="07:00:00"
            slotMaxTime="19:00:00"
            // Highlight working hours (Monday-Friday) within the calendar.
            businessHours={{
              daysOfWeek: [1, 2, 3, 4, 5, 6], // Monday - Friday
              startTime: '07:00',
              endTime: '19:00'
            }}
            events={calendarEvents}
            eventClick={(info) => {
              // Pass the full meeting object to the parent.
              onEventClick(info.event.extendedProps);
            }}
            height="auto"
            
          />
        )}
      </Card.Body>
    </Card>
  );
};

export default MeetingCalendar;
